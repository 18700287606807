<template>
  <div style="background-color: #f5f6fa; height: 100%">
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div>
        <!-- <img :src="details.PosterUrl"
          style="width:100%;height:290px;" /> -->
        <van-swipe :autoplay="3000" @change="onChange">
          <van-swipe-item v-for="item in ImgsList" :key="item + 'aa'">
            <img :src="item" style="width: 100%; height: 249px" />
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">
              {{ count + 1 }}/{{ ImgsList.length }}
            </div>
          </template>
        </van-swipe>
        <div class="middle">
          <div style="display: flex; justify-content: space-between">
            <div class="title">{{ details.Name }}</div>
            <div class="title" style="color: #666666; font-size: 14px">
              <img
                src="https://dximg.dexian.ren/lczhsq/tel.png"
                alt=""
                style="width: 18px; vertical-align: middle; margin-right: 4px"
              />{{ details.TouchTel }}
            </div>
          </div>
          <!-- <div class="tips">免费停车 ❘ 24小时前台</div> -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 24px;
            "
          >
            <!-- <div class="left"></div> -->
            <div class="right" @click="gotominiProgram(details)">
              {{ details.Address }}
              <img
                style="
                  width: 11.5px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 15px;
                "
                src="https://dximg.dexian.ren/lczhsq/point.png"
                alt=""
              />
            </div>
          </div>
          <div class="day" @click="show = true">
            <div>{{ start }}</div>
            <span class="day1">{{ weekStart }}入住</span>
            <div class="differ">共{{ DiffDay }}晚</div>
            <div>{{ end }}</div>
            <span class="day1">{{ weekEnd }}离店</span>
            <img
              style="width: 4px; position: absolute; right: 10px"
              src="https://dximg.dexian.ren/lczhsq/right.png"
              alt=""
            />
          </div>
        </div>
        <!-- 日期 -->
        <van-calendar
          v-model="show"
          type="range"
          :formatter="formatter"
          @confirm="onConfirm"
        />

        <div class="roomType" v-if="roomTypeList.length != 0">
          <div
            style="display: flex; margin-bottom: 15px"
            v-for="item in roomTypeList"
            :key="item.index"
          >
            <div style="width: 75px; height: 87px; margin-right: 7px">
              <img
                v-if="item.Img != null && item.Img != ''"
                style="border-radius: 8px; width: 100%; height: 100%"
                :src="item.Img[0]"
              />
              <img
                v-else
                style="border-radius: 8px; width: 100%; height: 100%"
                src="https://dximg.dexian.ren/lczhsq/roomType.png"
              />
            </div>
            <div style="box-sizing: border-box; padding: 3px 0; width: 74%">
              <div class="roomName">{{ item.Name }}</div>
              <div class="des">
                {{ item.Area }}㎡&nbsp;&nbsp;
                {{ item.IsMealDesc }}&nbsp;&nbsp;{{
                  item.GuestNum
                }}入住&nbsp;&nbsp;{{ item.IsSmokeDesc }}
              </div>

              <div
                style="
                  display: flex;
                  margin-top: 2px;
                  justify-content: space-between;
                  align-items: baseline;
                "
              >
                <div class="less">
                  <span v-if="item.FreeRoomNum <= 1">房量紧张</span>
                </div>
                <div class="price">
                  ¥<span class="num">{{ item.UnitPrice }}</span
                  >起<van-button @click="roomTypeXq(item)" class="btn"
                    >订</van-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="border-bottom: 10px solid #f8f8f8; padding: 10px 0">
          <div style="width: 95%; margin: auto">
            <div class="biaoti">
              <span class="title">{{ details.Name }}</span>
              <span class="money">{{ details.StatusDesc }} </span>
            </div>
            <div style="margin-top: 5px; font-size: 13px; font-weight: 500">
              房间数：{{ details.RoomNum }}；床位数：{{ details.BedNum }}
            </div>
            <div class="shopType">{{ details.GradeDesc }}</div>
            <div style="display: flex; justify-content: space-between">
              <div>
                <div class="address">
                  <van-icon name="location-o" /> {{ details.Address }}
                </div>
                <div class="address">
                  <van-icon name="phone-o" />
                  <a :href="'tel:' + details.TouchTel" style="color: black">{{
                    details.TouchTel
                  }}</a>
                </div>
              </div>
              <div @click="gotominiProgram(details)">
                <div style="width: 30px; height: 30px">
                  <img src="../../assets/定位.png" alt="" style="width: 100%" />
                </div>

                <div class="ditu">地图</div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 可定房间类型 -->
        <!-- <div>
          <div class="information" style="margin-bottom: 55px">
            <div
              class="center"
              style="padding: 0"
              v-for="item in roomTypeList"
              :key="item.index"
            >
              <div @click="roomTypeXq(item)">
                <van-card class="vanCard">
                  <template #thumb>
                    <div class="van-card__thumb">
                      <img
                        v-if="item.Img != null && item.Img != ''"
                        style="border-radius: 5px; width: 100%"
                        :src="item.Img"
                      />
                      <img
                        v-else
                        style="border-radius: 5px; width: 100%"
                        src="http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png"
                      />
                    </div>
                  </template>
                  <template #title>
                    <div style="line-height: 20px">
                      <div
                        class="centerTitle van-ellipsis--l1"
                        style="width: 75%"
                      >
                        {{ item.Name }}
                      </div>
                      <div style="color: #808080">
                        <span>{{ item.IsMealDesc }}&nbsp;&nbsp;</span>
                        <span>{{ item.Area }}㎡&nbsp;&nbsp;</span>
                        <span>{{ item.GuestNum }}人入住</span>
                      </div>
                      <div style="color: #004097">
                        <span>{{ item.Cost }} </span>
                      </div>
                      <div style="display: flex">
                        <div style="width: 15px; margin: 2px 5px 2px 0">
                          <img
                            src="../../assets/禁烟区.png"
                            alt=""
                            style="width: 100%"
                          />
                        </div>
                        <span>{{ item.IsSmokeDesc }}</span>
                      </div>
                    </div>
                  </template>

                  <template #num>
                    <div style="color: #d8222b; font-weight: 550">
                      <span>￥</span>
                      <span style="font-size: 18px">{{ item.UnitPrice }}</span>
                    </div>
                  </template>
                </van-card>
              </div>
            </div>
          </div>
        </div> -->
        <div
          class="card"
          v-if="details.Introduce != null && details.Introduce != ''"
        >
          <div style="margin-bottom: 10px">
            <div class="zhuyao">—&nbsp;&nbsp;民宿详情&nbsp;&nbsp;—</div>
          </div>
          <div
            style="color: #666666; font-size: 14px"
            v-html="details.Introduce"
          ></div>
        </div>
      </div>
      <!-- <div style="width:100%;">
        <van-button class="button"
          @click="Activity(details.PromotionId)"
          style="width:95%;"
          round
          type="info">活动申请</van-button>
      </div> -->
    </div>
    <!-- 房屋类型详情 -->

    <van-popup
      v-model="roomTypeDetailShow"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
      :style="{ height: '80%', overflow: 'hidden' }"
    >
      <div class="popup_box">
        <div class="popup_top">
          <div class="title">{{ roomTypeDetail.Name }}</div>
          <van-icon name="cross" @click="roomTypeDetailShow = false" />
        </div>
        <div class="popup_main">
          <div>
            <van-swipe class="popupImg" :autoplay="3000" @change="onChange1">
              <van-swipe-item v-for="item in ImgDetail" :key="item + 'rr'">
                <img :src="item" style="width: 100%; height: 208px" />
              </van-swipe-item>
              <template #indicator>
                <div class="custom-indicator1">
                  {{ count1 + 1 }}/{{ ImgDetail.length }}
                </div>
              </template>
            </van-swipe>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 16px;
            "
          >
            <div class="li">
              <img
                style="width: 21px"
                src="https://dximg.dexian.ren/lczhsq/area.png"
                alt=""
              />
              <div>{{ roomTypeDetail.Area }}㎡</div>
            </div>
            <div class="li">
              <img
                style="width: 21px"
                src="https://dximg.dexian.ren/lczhsq/floor.png"
                alt=""
              />
              <div>{{ roomTypeDetail.Floor }}层</div>
            </div>
            <div class="li">
              <img
                style="width: 21px"
                src="https://dximg.dexian.ren/lczhsq/wifi.png"
                alt=""
              />
              <div>
                WIFI<span
                  v-if="roomTypeDetail.IsWifi == 1"
                  style="color: #0c9565"
                  >{{ roomTypeDetail.IsWifiDesc }}</span
                ><span v-else>&nbsp;无</span>
              </div>
            </div>
            <div class="li">
              <img
                style="width: 21px"
                src="https://dximg.dexian.ren/lczhsq/windows.png"
                alt=""
              />
              <div>{{ roomTypeDetail.IsWindowsDesc }}</div>
            </div>
            <div class="li">
              <img
                style="width: 21px"
                src="https://dximg.dexian.ren/lczhsq/smoke.png"
                alt=""
              />
              <div>{{ roomTypeDetail.IsSmokeDesc }}</div>
            </div>
          </div>
          <div
            style="display: flex; align-items: center; margin: 41px 22px 25px"
          >
            <img
              style="width: 21.5px; margin-right: 25px"
              src="https://dximg.dexian.ren/lczhsq/bed.png"
              alt=""
            />
            <div class="bed">
              {{ roomTypeDetail.BedDesc }}
            </div>
          </div>
          <shrink-view v-model="open">
            <!--浴室配套 -->
            <div
              class="box"
              v-if="
                roomTypeDetail.Bathing != '' || roomTypeDetail.Bathroom != ''
              "
            >
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/bathing.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">浴室配套</div>
                </van-col>
                <van-col span="18">
                  <span
                    v-if="roomTypeDetail.Bathing"
                    style="display: flex; flex-wrap: wrap"
                  >
                    <div
                      class="roomTypeDetailName"
                      v-for="(item, index) in roomTypeDetail.Bathing.split(
                        '，'
                      )"
                      :key="index"
                      style="width: 48%; line-height: 22px"
                    >
                      {{ item }}
                    </div>
                  </span>
                  <span
                    v-if="roomTypeDetail.Bathroom"
                    style="display: flex; flex-wrap: wrap"
                  >
                    <div
                      class="roomTypeDetailName"
                      v-for="(item, index) in roomTypeDetail.Bathroom.split(
                        '，'
                      )"
                      :key="index"
                      style="width: 48%; line-height: 22px"
                    >
                      {{ item }}
                    </div>
                  </span>
                </van-col>
              </van-row>
            </div>
            <!-- 早餐 -->
            <div class="box">
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/meal.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">早餐</div>
                </van-col>
                <van-col span="18">
                  <div class="roomTypeDetailName">
                    {{ roomTypeDetail.IsMealDesc }}
                  </div>
                </van-col>
              </van-row>
            </div>
            <!-- 媒体科技 -->
            <div class="box" v-if="roomTypeDetail.Medium != ''">
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/medium.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">媒体</div>
                  <div class="roomTypeDetailTitle">科技</div>
                </van-col>
                <van-col span="18">
                  <span
                    v-if="roomTypeDetail.Medium"
                    style="display: flex; flex-wrap: wrap"
                  >
                    <div
                      class="roomTypeDetailName"
                      v-for="(item, index) in roomTypeDetail.Medium.split('，')"
                      :key="index"
                      style="width: 48%; line-height: 22px"
                    >
                      {{ item }}
                    </div>
                  </span>
                </van-col>
              </van-row>
            </div>
            <!-- 其他设施 -->
            <div class="box" v-if="roomTypeDetail.Other != ''">
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/otner.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">其他</div>
                  <div class="roomTypeDetailTitle">设施</div>
                </van-col>
                <van-col span="18">
                  <span
                    v-if="roomTypeDetail.Other"
                    style="display: flex; flex-wrap: wrap"
                  >
                    <div
                      class="roomTypeDetailName"
                      v-for="(item, index) in roomTypeDetail.Other.split('，')"
                      :key="index"
                      style="width: 48%; line-height: 22px"
                    >
                      {{ item }}
                    </div>
                  </span>
                </van-col>
              </van-row>
            </div>
            <!-- 便利设施 -->
            <div class="box" v-if="roomTypeDetail.Amenity != ''">
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/Amenity.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">便利</div>
                  <div class="roomTypeDetailTitle">设施</div>
                </van-col>
                <van-col span="18">
                  <div class="roomTypeDetailName">
                    {{ roomTypeDetail.Amenity }}
                  </div>
                </van-col>
              </van-row>
            </div>
            <!-- 食品饮品 -->
            <div class="box" v-if="roomTypeDetail.Food != ''">
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/food.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">食品</div>
                  <div class="roomTypeDetailTitle">饮品</div>
                </van-col>
                <van-col span="18">
                  <div class="roomTypeDetailName">
                    {{ roomTypeDetail.Food }}
                  </div>
                </van-col>
              </van-row>
            </div>
            <!-- 费用政策 -->
            <div class="box" v-if="roomTypeDetail.Cost != ''">
              <van-row gutter="20" style="display: flex; align-items: center">
                <van-col span="3">
                  <img
                    style="width: 23.5px; margin-bottom: 2px"
                    src="https://dximg.dexian.ren/lczhsq/cost.png"
                    alt=""
                  />
                  <div class="roomTypeDetailTitle">费用</div>
                  <div class="roomTypeDetailTitle">政策</div>
                </van-col>
                <van-col span="18">
                  <div class="roomTypeDetailName">
                    {{ roomTypeDetail.Cost }}
                  </div>
                </van-col>
              </van-row>
            </div>
            <!-- <div style="margin-top: 15px" v-if="roomTypeDetail.Medium != ''">
          <van-row gutter="20">
            <van-col span="6">
              <span class="roomTypeDetailTitle">媒体科技</span>
            </van-col>
            <van-col span="18">
              <span class="roomTypeDetailName">{{
                roomTypeDetail.Medium
              }}</span>
            </van-col>
          </van-row>
        </div> -->
            <!-- <div style="margin-top: 15px" v-if="roomTypeDetail.Bathroom != ''">
          <van-row gutter="20">
            <van-col span="6">
              <span class="roomTypeDetailTitle">浴室配套</span>
            </van-col>
            <van-col span="18">
              <span class="roomTypeDetailName">{{
                roomTypeDetail.Bathroom
              }}</span>
            </van-col>
          </van-row>
        </div>
        <div style="margin-top: 15px" v-if="roomTypeDetail.Bathing != ''">
          <van-row gutter="20">
            <van-col span="6">
              <span class="roomTypeDetailTitle">洗浴用品</span>
            </van-col>
            <van-col span="18">
              <span class="roomTypeDetailName">{{
                roomTypeDetail.Bathing
              }}</span>
            </van-col>
          </van-row>
        </div>
        <div style="margin-top: 15px" v-if="roomTypeDetail.Other != ''">
          <van-row gutter="20">
            <van-col span="6">
              <span class="roomTypeDetailTitle">其他设施</span>
            </van-col>
            <van-col span="18">
              <span class="roomTypeDetailName">{{ roomTypeDetail.Other }}</span>
            </van-col>
          </van-row>
        </div> -->
          </shrink-view>
          <div
            class="icon"
            @click="openClick"
            style="text-align: center; margin: 10px"
          >
            <span class="more">{{ open ? "收起" : "更多房型设施" }}</span>
            <van-icon v-if="open" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </div>
          <div style="width: 95%; margin: 15px auto 50px">
            <!-- <div class="detailTitle">
          {{ roomTypeDetail.Name }}
        </div> -->

            <!-- <div style="margin-top: 15px">
          <van-row gutter="20">
            <van-col span="16">
              <span><img src="../../assets/床.png" alt="" width="15" /></span>
              <span class="roomTypeDetailName">{{
                roomTypeDetail.BedDesc
              }}</span>
            </van-col>
            <van-col span="8">
              <span
                ><img src="../../assets/房屋面积.png" alt="" width="15"
              /></span>
              <span class="roomTypeDetailName">{{ roomTypeDetail.Area }}</span>
            </van-col>
          </van-row>
        </div>
        <div style="margin-top: 15px">
          <van-row gutter="20">
            <van-col span="8">
              <span><img src="../../assets/楼层.png" alt="" width="15" /></span>
              <span class="roomTypeDetailName">{{ roomTypeDetail.Floor }}</span>
            </van-col>
            <van-col span="8">
              <span><img src="../../assets/窗户.png" alt="" width="15" /></span>
              <span class="roomTypeDetailName">{{
                roomTypeDetail.IsWindowsDesc
              }}</span>
            </van-col>
            <van-col span="8">
              <span><img src="../../assets/wifi.png" alt="" width="15" /></span>
              <span class="roomTypeDetailName">
                <span v-if="roomTypeDetail.IsWifi == 1">有（免费）</span>
                <span v-if="roomTypeDetail.IsWifi == 2">无</span>
              </span>
            </van-col>
          </van-row>
        </div>
        <div style="margin-top: 15px">
          <van-row gutter="20">
            <van-col span="8">
              <span
                ><img src="../../assets/禁烟区.png" width="15" alt=""
              /></span>
              <span class="roomTypeDetailName">{{
                roomTypeDetail.IsSmokeDesc
              }}</span>
            </van-col>
            <van-col span="8">
              <span><img src="../../assets/多人.png" alt="" width="15" /></span>
              <span class="roomTypeDetailName">{{
                roomTypeDetail.GuestNum
              }}</span>
            </van-col>
            <van-col span="8">
              <span><img src="../../assets/早餐.png" alt="" width="15" /></span>
              <span class="roomTypeDetailName">{{
                roomTypeDetail.IsMealDesc
              }}</span>
            </van-col>
          </van-row>
        </div>
        <div style="margin-top: 15px">
          <van-row gutter="20">
            <van-col span="6">
              <span class="roomTypeDetailTitle">食品饮品</span>
            </van-col>
            <van-col span="18">
              <span class="roomTypeDetailName">{{ roomTypeDetail.Food }}</span>
            </van-col>
          </van-row>
        </div>
        <div style="margin-top: 15px" v-if="roomTypeDetail.Cost != ''">
          <van-row gutter="20">
            <van-col span="6">
              <span class="roomTypeDetailTitle">费用政策</span>
            </van-col>
            <van-col span="18">
              <span class="roomTypeDetailName">{{ roomTypeDetail.Cost }}</span>
            </van-col>
          </van-row>
        </div> -->

            <!-- <div @click="openClick" style="text-align: center; margin: 10px">
          <span class="more">{{ open ? "收起" : "查看更多" }}</span>
          <van-icon v-if="open" name="arrow-up" />
          <van-icon v-else name="arrow-down" />
        </div> -->
          </div>
        </div>
        <van-submit-bar
          :price="roomTypeDetail.UnitPrice * 100"
          style="background-color: #f5f6fa"
          :disabled="disabled"
          :button-text="buttonText"
          label=" "
          button-color="#fe411b"
          @submit="onSubmit(roomTypeDetail)"
        >
        </van-submit-bar>
      </div>
    </van-popup>
  </div>
</template>

<script>
import ShrinkView from "@/components/ShrinkView";
import {
  WxGetHotel,
  WxEnrolPromotion,
  WxGetHRoomTypeList,
  HGetMyRoomType,
  Wx_GetHotelImgs,
} from "@/api/RealInfo";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    ShrinkView,
  },
  data() {
    return {
      weekStart: "",
      weekEnd: "",
      show: false, // 时间
      open: false,
      details: {},
      count: "",
      count1: "",
      ImgsList: [],
      list: [],
      strategy: {},
      landScape: [],
      url: config.hostUrl,
      roomTypeList: [],
      roomTypeDetail: {},
      ImgDetail: [],
      roomTypeDetailShow: false,
      disabled: false,
      buttonText: "立即预定",
      countNum: "",
      start: this.formatDate(new Date()),
      end: this.formatDate(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      ),
      Start: this.formatDateTime(new Date()),
      End: this.formatDateTime(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      ),
      DiffDay: 1,
    };
  },
  created() {
    this.wxGetPromotion();
    this.getHRoomTypeList();
    var start = new Date();
    var dayStart = this.Week(start.getDay()); //获取星期几
    this.weekStart = dayStart;
    var end = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var dayEnd = this.Week(end.getDay()); //获取星期几
    this.weekEnd = dayEnd;
  },
  methods: {
    onBack() {
      window.history.back(-1); //返回上一层
    },
    onConfirm(date) {
      console.log(date, "date");
      const [start, end] = date;
      var dayStart = this.Week(start.getDay()); //获取星期几
      this.weekStart = dayStart;
      var dayEnd = this.Week(end.getDay()); //获取星期几
      this.weekEnd = dayEnd;
      this.show = false;
      this.start = this.formatDate(start); //08-03
      this.Start = this.formatDateTime(start); // 2023-08-10
      this.end = this.formatDate(end);
      this.End = this.formatDateTime(end);
      this.DiffDay = this.getDiffDay(start, end);
      // this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    Week(dayStart) {
      if (dayStart === 0) {
        return "周日";
      } else if (dayStart === 1) {
        return "周一";
      } else if (dayStart === 2) {
        return "周二";
      } else if (dayStart === 3) {
        return "周三";
      } else if (dayStart === 4) {
        return "周四";
      } else if (dayStart === 5) {
        return "周五";
      } else if (dayStart === 6) {
        return "周六";
      }
    },
    formatter(day) {
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      // console.log(month, "month");
      // console.log(date, "date");
      if (month === 5) {
        if (date === 1) {
          day.topInfo = "劳动节";
        } else if (date === 4) {
          day.topInfo = "青年节";
        } else if (date === 11) {
          day.text = "今天";
        }
      }

      if (day.type === "start") {
        day.bottomInfo = "入住";
      } else if (day.type === "end") {
        day.bottomInfo = "离店";
      }

      return day;
    },
    // 点击展开隐藏
    openClick() {
      this.open = !this.open;
    },
    // 预定
    onSubmit(val) {
      console.log(this.Start);
      console.log(this.End);
      // var url = '/hotels/hotelsXq/reserve/' + val.TypeId
      // this.$router.push(url);
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url:
            "../reserve/index?typeId=" +
            +val.TypeId +
            "&start=" +
            this.Start +
            "&end=" +
            this.End,
        });
      } else {
        var url =
          "/hotels/hotelsXq/reserve/" +
          val.TypeId +
          "/" +
          this.Start +
          "/" +
          this.End;
        this.$router.push(url);
      }
    },
    getDiffDay(date_1, date_2) {
      // 计算两个日期之间的差值
      let totalDays, diffDate;
      let myDate_1 = Date.parse(date_1);
      let myDate_2 = Date.parse(date_2);
      // 将两个日期都转换为毫秒格式，然后做差
      diffDate = Math.abs(myDate_1 - myDate_2); // 取相差毫秒数的绝对值
      totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
      // console.log(totalDays)
      return totalDays; // 相差的天数
    },
    formatDateTime(val) {
      var timestamp = Date.parse(val);
      var date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      //获取当日日期
      // console.log();
      var day = date.getDate();
      var D = day < 10 ? "0" + day : day;

      this.minDate = new Date(Y, M - 1, D);
      return Y + "-" + M + "-" + D;
    },
    formatDate(date) {
      if (date.getMonth() + 1 <= 9) {
        var month = `0${date.getMonth() + 1}`;
      } else {
        var month = `${date.getMonth() + 1}`;
      }
      if (date.getDate() <= 9) {
        var day = `0${date.getDate()}`;
      } else {
        var day = `${date.getDate()}`;
      }
      return `${month}-${day}`;
    },
    // 获取可预约的房屋列表
    getHRoomTypeList() {
      WxGetHRoomTypeList({
        hotelId: this.$route.params.hotelId,
      }).then((res) => {
        if (res.data.code == 0) {
          this.roomTypeList = res.data.data;
          for (let i = 0; i < this.roomTypeList.length; i++) {
            //   const e = this.roomTypeList[i];
            if (res.data.data[i].Img != "" && res.data.data[i].Img != null) {
              this.roomTypeList[i].Img = res.data.data[i].Img.split(",");
            } else {
              Wx_GetHotelImgs({
                hotelId: this.$route.params.hotelId,
              }).then((res) => {
                if (res.data.code == 0) {
                  if (res.data.data) {
                    this.roomTypeList[i].Img = res.data.data.split(",");
                  } else {
                    this.roomTypeList[i].Img = [
                      "https://dximg.dexian.ren/lczhsq/roomType.png",
                    ];
                  }
                }
              });
            }
          }

          // console.log(this.roomTypeList);
        }
      });
    },
    // 点击房屋类型列表
    roomTypeXq(val) {
      // console.log(val);
      this.roomTypeDetailShow = true;
      this.roomTypeDetail = val;
      console.log(this.roomTypeDetail.Bathing.split("，"));
      var countNum = val.FreeRoomNum + val.WaitLeaveRoomNum;
      if (countNum > 0) {
        this.disabled = false;
        this.buttonText = "立即预定";
      } else {
        this.disabled = true;
        this.buttonText = "已满";
      }
      // console.log(val.Img);
      if (val.Img != "" && val.Img != null) {
        // console.log(val.Img);
        this.ImgDetail = val.Img;
        // console.log(123321);
      } else {
        // console.log(123);
        this.ImgDetail =
          "http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png" + [];
      }
      console.log(this.ImgDetail);
      // HGetMyRoomType({
      //   openID: getOpenId(),
      //   hotelId: val.HotelId,
      //   typeId: val.TypeId,
      // }).then((res) => {
      //   if (res.data.code == 0) {
      //     this.roomTypeDetail = res.data.data
      //     if (res.data.data.Img != '' && res.data.data.Img != null) {
      //       this.ImgDetail = res.data.data.Img.split(',')
      //     } else {
      //       this.ImgDetail = 'http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png' + []
      //     }
      //   }
      //   console.log(this.ImgDetail);
      // })
    },
    //格式化日期
    filteryear(val) {
      // let gstime = val.activationDate.replace('T', ' ')
      if (val.StartDate != null && val.StartDate != "") {
        let gstime = val.StartDate.substring(0, 10);
        return gstime;
      }
    },
    onChange(index) {
      this.count = index;
    },
    onChange1(index) {
      this.count1 = index;
    },
    wxGetPromotion() {
      WxGetHotel({ hotelId: this.$route.params.hotelId }).then((res) => {
        this.details = res.data.data;
        // console.log(this.details);
        if (res.data.data.Imgs != "" && res.data.data.Imgs != null) {
          // console.log(123321);
          this.ImgsList = res.data.data.Imgs.split(",");
          // this.details.Imgs = ImgsList
        } else {
          this.ImgsList = [
            "http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png",
          ];
        }
        // console.log(this.ImgsList);
        // this.details.StartDate = res.data.data.StartDate.substring(0, 10);
        // this.details.EndDate = res.data.data.EndDate.substring(0, 10);
      });
    },
    gotominiProgram(details) {
      // console.log(details);
      if (details.Coordinate == "" || details.Coordinate == null) {
        Toast.fail("暂无位置信息");
      } else {
        this.nameCc = {
          Navigation: "导航",
          Coordinate: details.Coordinate,
        };
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../openminip/openminip?name=" + JSON.stringify(this.nameCc),
          });
        } else {
          alert("请在小程序中打开");
        }
      }
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Date";
  src: url(../../assets/font/DIN-MEDIUM.OTF);
}
@font-face {
  font-family: "Price";
  src: url(../../assets/font/OPPOSANS-H.TTF);
}
.essay {
  /* height: 100%; */
  width: 100%;
  margin: auto;
  padding-bottom: 25px;
  background: #f5f6fa;
}
.custom-indicator {
  position: absolute;
  right: 0px;
  bottom: 70px;
  padding: 4px 15px;
  font-size: 14px;
  font-family: PingFang SC;
  color: #ffffff;
  background: #00000045;
  border-radius: 15px 0px 0px 15px;
  text-align: center;
  line-height: 20px;
}
.custom-indicator1 {
  position: absolute;
  right: 0px;
  bottom: 15px;
  padding: 4px 15px;
  font-size: 14px;
  font-family: PingFang SC;
  color: #ffffff;
  background: #00000045;
  border-radius: 15px 0px 0px 15px;
  text-align: center;
  line-height: 20px;
}
.middle {
  width: 94%;
  margin: auto;
  height: 200px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  top: -36px;
  box-sizing: border-box;
  padding: 15px 12px;
}

.tips {
  margin-top: 8px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #6f6f6f;
}

.left {
  width: 41.6%;
  height: 76px;
  background: #f1fcfe;
  border-radius: 8px;
}
.right {
  position: relative;
  /* width: 56.5%; */
  width: 100%;
  height: 76px;
  background: #f1fcfe;
  border-radius: 8px;
  background-image: url(https://dximg.dexian.ren/lczhsq/map.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
  text-align: left;
  line-height: 76px;
  box-sizing: border-box;
  padding-left: 20px;
}

.day {
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 35px;
  background: #f1fcfe;
  border-radius: 8px;
  font-size: 18px;
  font-family: Date;
  font-weight: 500;
  color: #1e1e1e;
}

.day1 {
  font-size: 12px;
  color: #666666;
  margin-left: 2px;
}

.differ {
  width: 41px;
  height: 16px;
  line-height: 16px;
  border: 1px solid #2dd6ea;
  border-radius: 8px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  text-align: center;
  color: #0dd3e8;
  margin: 0 12px;
}
/deep/ .van-calendar__confirm {
  height: 42px;
}
/deep/ .van-calendar__day--start,
/deep/ .van-calendar__day--end {
  color: #1e1e1e;
  background-color: #f8de4b;
}

/deep/ .van-calendar__day--middle::after {
  background-color: #f8de4b;
  opacity: 0.19;
}
/deep/ .van-calendar__day--middle {
  color: #1e1e1e;
}
/deep/ .van-button--danger {
  background: #fede00;
  border: 1px solid #fede00;
  color: #1e1e1e;
}

.roomType {
  margin: auto;
  margin-top: -24px;
  width: 90%;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 12px 8px;
}

.roomName {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
}
.des {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 7px;
  /* 超过1行 隐藏 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.less {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff7300;
}
.price {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fe411b;
}

.box {
  padding: 25px 0px 25px 21px;
  border-top: 0.5px solid #f0f0f0;
  /* border-bottom: 0.5px solid #f0f0f0; */
}
.bed {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
  /* 超过1行 隐藏 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.price .num {
  font-size: 18px;
  font-family: Price;
  font-weight: 500;
  color: #fe411b;
}
.price .btn {
  vertical-align: bottom;
  width: 33px;
  height: 36px;
  background: #ff7300;
  border-radius: 4px;
  border-color: #ff7300;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-left: 8px;
}

.card {
  width: 90%;
  margin: auto;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 21px 12px;
}
.zhuyao {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
  text-align: center;
}

.li {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

/* 旧的样式 */
.biaoti {
  display: flex;
  justify-content: space-between;
}
.money {
  line-height: 33px;
  font-family: PingFang SC;
  font-weight: 550;
  color: #fe5b47;
  font-size: 18px;
}
.time {
  /* display: flex;
  justify-content: space-between; */
  padding: 5px 0;
  color: #0c0c0c;
  font-weight: bold;
  line-height: 30px;
}

.zhuyaoJd {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.zhuyaoJd span {
  color: rgb(153, 153, 153);
  float: right;
  font-size: 12pt;
  font-weight: 300;
}
.waterfall {
  /* width: 95%; */
  margin: 10px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  font-weight: 550;
  font-size: 14px;
}
.shopType {
  background: #0646a7;
  color: #fff;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 12px;
  display: inline-block;
  margin-top: 10px;
  /* margin-left: 6px; */
}
.mapImg {
  background-image: url("http://xczxapi.zhsq.cloud//upload/images/2022/6/1ee98423-7b1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.addressImage {
  width: 20px;
  height: 25px;
  margin-top: 8px;
}
.ditu {
  font-size: 14px;
  margin-top: 5px;
  color: #0c55b4;
  font-weight: 550;
}
.information .center {
  /* border-bottom: none; */
  /* border-bottom: 1px solid #f4f4f4; */
  border-radius: 5px;
  margin: 10px auto;
  width: 95%;
  padding: 5px;
  border-radius: 5px;
}
.centerTitle {
  font-size: 15px;
}
.vanCard {
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
}
.shop .center {
  width: 95%;
  border-radius: 8px;
  margin: 10px auto;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
  /* background: rgb(246, 243, 243); */
}
W .detailTitle {
  font-size: 18px;
  font-weight: 550;
}
.roomTypeDetailTitle {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}
.roomTypeDetailName {
  padding-left: 5px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: normal;
  color: #1e1e1e;
}
.more {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #06d7e4;
}
/deep/ .icon .van-icon {
  color: #06d7e4;
  font-size: 12px;
  margin-left: 3px;
}

/deep/ .van-submit-bar__bar {
  padding: 6px 16px;
}

/deep/ .van-submit-bar__button {
  background-color: #fe411b !important;
  border-color: #fe411b !important;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
/deep/ .van-submit-bar__text {
  text-align: left;
}
/deep/ .van-submit-bar__price {
  font-family: Price;
}
/deep/ .van-submit-bar__price--integer {
  font-size: 22px;
}

.popup_box {
  height: 80%;
  background-color: #fff;
  padding-top: 48px;
  /* padding-bottom: 1.31rem; */
  overflow: hidden;
  position: relative;
}
.popup_top {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  height: 48px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.van-icon-cross {
  color: #181818;
}
.title {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
}
.popup_main {
  height: 100%;
  overflow-y: scroll;
  /* background-color: rgb(245, 223, 245); */
}
</style>